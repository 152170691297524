













import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import validation from '@/common/element-val'
import { RssFeeds } from '@/services/symbolService'

@Component
export default class RssFeedForm extends Vue {
  $refs: {
    modelForm: ElForm;
  }

  @Prop() FeedName: string
  @Prop() Link: string

  model: RssFeeds = {
    SymbolId: null,
    FeedName: '',
    Link: ''
  }

  rules = {
    FeedName: validation.requiredRule(),
    Link: validation.requiredRule()
  }

  mounted () {
    this.model.FeedName = this.FeedName
    this.model.Link = this.Link
  }

  @Watch('FeedName')
  onTitleChanged (v: string) {
    this.model.FeedName = v
  }

  @Watch('Link')
  onUrlChanged (v: string) {
    this.model.Link = v
  }

  // if use @Emit,do not use this.$emit again, @Emit will return the function value to parent
  submit () {
    this.$refs.modelForm.validate((valid: boolean) => {
      if (valid) {
        this.$emit('submit', this.model)
      }
    })
  }
}
