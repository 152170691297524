import { PortfolioMessage, Price, PriceMsgContent } from '@/services/data'

export function setPriceValues (content: PriceMsgContent, priceItem: Price) {
  if (content.Value != null && priceItem) {
    if (content.Name === 'Last') {
      priceItem.LastUpdated = content.LastTime
      priceItem.Last = content.Value
    } else if (content.Name === 'Open') {
      priceItem.Open = content.Value
    } else if (content.Name === 'Close') {
      priceItem.Close = content.Value
    } else if (content.Name === 'Volume') {
      priceItem.Volume = content.Value
    } else if (content.Name === 'AvgVolume') {
      priceItem.AvgVolume = content.Value
    } else if (content.Name === 'Bid') {
      priceItem.Bid = content.Value
    }
  }
}

export interface Group<T> {
  Key: any;
  Entries: T[];
}

export function groupBy<T> (arr: Array<T>, keySelector: (entry: T) => any): Group<T>[] {
  const groups: Group<T>[] = []
  for (const row of arr) {
    const key = keySelector(row)
    const find = groups.find(p => p.Key === key)
    if (find) {
      find.Entries.push(row)
    } else {
      groups.push({ Key: key, Entries: [row] })
    }
  }
  return groups
}

export function calcStrikePro (row: PortfolioMessage, percent: number) {
  if (percent && row.SecType === 'OPT') {
    if (row.OptionData.Right === 'C') {
      const target = (100 - percent) / 100.0 * row.OptionData.Strike
      if (row.StockPrice.Last >= target && row.StockPrice.Last < row.OptionData.Strike) {
        row.StrikeProx = 'NTM'
      } else if (row.StockPrice.Last >= row.OptionData.Strike) {
        row.StrikeProx = 'ITM'
      } else if (row.StockPrice.Last < target) {
        row.StrikeProx = 'OOM'
      }
    }
    if (row.OptionData.Right === 'P') {
      const target = (100 + percent) / 100.0 * row.OptionData.Strike
      if (row.StockPrice.Last <= target && row.StockPrice.Last > row.OptionData.Strike) {
        row.StrikeProx = 'NTM'
      } else if (row.StockPrice.Last <= row.OptionData.Strike) {
        row.StrikeProx = 'ITM'
      } else if (row.StockPrice.Last > target) {
        row.StrikeProx = 'OOM'
      }
    }
  }
}
