






































import { Component, Vue } from 'vue-property-decorator'
import symbolService, { RssFeeds, RssNews } from '@/services/symbolService'
import { PriceMsgContent, StockPrice } from '@/services/data'
import { setPriceValues } from '@/services/func'
import ws, { SocketMsg } from '@/services/socket'
import RssFeedForm from '@/views/Home/RssFeedForm.vue'
import utility from '@/common/utility'
import SymbolName from '@/components/SymbolName.vue'

@Component({
  components: {
    RssFeedForm,
    SymbolName
  }
})
export default class Account extends Vue {
  isLoading = false
  symbolId: number
  symbolPrice: StockPrice = null
  feeds: RssFeeds[] = []
  showAddFeedDialog = false
  editingFeed: RssFeeds = {
    SymbolId: null,
    FeedName: '',
    Link: ''
  }

  selectedRssFeed: string = null
  news: RssNews[] = []

  async mounted () {
    const symbolId: number = parseInt(this.$route.query.symbolId as string)
    ws.InitAll(this.msgCallBack)

    this.symbolId = symbolId
    ws.SendAll({ page: 'News', symbols: [this.symbolId] })
    const res = await symbolService.getSymbolPriceAsync(symbolId)
    console.log(res)
    if (res.Result) {
      this.symbolPrice = res.Result
    }
    await this.loadNews()
  }

  destroyed (): void {
    ws.RemoveAll(this.msgCallBack)
  }

  async rssFeedChanged () {
    await this.loadNews()
  }

  async loadNews () {
    const res = await symbolService.getRssNewsAsync(this.symbolId)
    if (res.Result) {
      this.news = utility.freezeArrayAndItems(res.Result)
    }
  }

  async msgCallBack (msg: SocketMsg) {
    if (msg.Action === 'resStk' && msg.MsgId === this.symbolId) {
      const content = msg.Content as PriceMsgContent
      setPriceValues(content, this.symbolPrice.Price)
    }
  }
}
