export interface ApiResult<T> {
  Error: string;
  Result: T;
}

export interface ApiBooleanResult {
  Error: string;
  Result: boolean;
}

export interface JwtResult {
  jwt: string;
  chromeToken: string;
}

export interface User {
  email: string;
  name: string;
  accNo: string;
  jwt: string;
  uid?: string;
  agentId?: string;
}

export interface MessageText {
  success?: string;
  error?: string;
}

export interface Sort {
  key: string;
  direction: string;
}

export interface SocketResponse<T> {
  Action: string;
  Content: T;
}

export interface ContractSymbol {
  SymbolUId: number;
  SymbolId: number;
  Symbol: string;
  SecType: string;
  Currency: string;
  PrimaryExch: string;
  CompanyName: string;
  CondId?: number;
  Description?: string;
  Detail?: any;
  IssuerId?: string;
}

export interface BasePortfolio {
  Position: number;
  MarketValue: number;
  AverageCost: number;
  DailyPL?: number;
}

export interface Option {
  Right: string;
  Multiplier: number;
  Expiration: string;
  Strike: number;
  Code: string;
}

export interface OptionRow {
  OptionId: number;
  OptionUId: number;
  LocalSymbol: string;
  Right: string;
  Multiplier: number;
  ExpirationDate: string;
  Strike: number;
  ComboType?: string;
}

export interface OptionSymbol extends OptionRow, ContractSymbol {
  Expiration: string;
}

export interface WatchOption {
  Option: OptionSymbol;
  Price: Price;
}

export interface CryptoSymbol
{
  CryptoId: number;
  Code: string;
  Name: string;
}

export enum SymbolType
{
    Stock = 1,
    Option = 2,
    Crypto = 3,
    Bond = 4
}

export interface OtmRange {
  SymbolId: number;
  Low: number;
  High: number;
  Run?: boolean;
  RangeType?: 'Download' | 'Freebie';
}

export interface OptionPrice {
  Option: OptionRow;
  Price: BasePrice;
}

export interface PortfolioMessage extends BasePortfolio {
  PortfolioId: number;
  SecType: string;
  ConId: number;
  AccountName: string;
  StockPrice?: Price;
  OptionData?: OptionRow;
  MarketPrice: number;
  UnrealizedPL: number;
  Change: number;
  ChangeRate: number;
  Return: number;
  Alert?: boolean;
  ExRate: number;
  Exposure?: number;
  StrikeProx?: string;
}

export interface PortfolioGroup {
  Symbol: ContractSymbol;
  Items: PortfolioMessage[];
  ComboType: number;
  IsExpanded?: boolean;
}

export class PortfolioGroupCls implements PortfolioGroup {
  constructor (group: PortfolioGroup) {
    this.Symbol = group.Symbol
    this.Items = group.Items
    this.ComboType = group.ComboType
  }

  Symbol: ContractSymbol
  Items: PortfolioMessage[]
  ComboType: number;
  IsExpanded? = true
}

export interface GroupedData<T> {
  SecType: string;
  Items: T[];
  Keys: string[];
  Sort: Sort;
}

export interface WatchListName {
  WatchListId: number;
  Name: string;
  Keys: string[];
  OptKeys: string[];
  CryptoKeys: string[];
  BondKeys: string[];
  IsSystem: boolean;
  IsReadOnly: boolean;
  FromUserId: number;
  Rank: number;
}

export interface WatchGroup {
  WatchName: WatchListName;
  Symbols: WatchSymbol[];
  Options: WatchOption[];
  Cryptos: WatchCrypto[];
  Rank?: number;
  LastUpdated?: string;
}

export interface BasePrice {
  Last: number;
  Bid?: number;
  Ask?: number;
  Open: number;
  Close: number;
}

export interface Price extends BasePrice {
  Volume?: number;
  AvgVolume?: number;
  LastUpdated?: string;
  NextEarnDate?: string;
  LastClosed?: number;
  Status?: number;
  BidYtm?: number;
  Bid?: number;
  DailyBid?: number;
  DailyBidYtm?: number;

  getLast? (): number;

  getClose? (): number;

  getChange? (): number;

  getChangeRate? (): number;

  getPreAfterChange? (): number;

  getPreAfterChangeRate? (): number;
}

const PreMarketStart = 4
const PreMarketEnd = 16
const InTrading = 8
const Closed = 64

export class PriceCls implements Price {
  Open: number
  Close: number
  Last: number
  Bid: number
  BidYtm?: number
  DailyBid?: number
  DailyBidYtm?: number
  Volume?: number
  AvgVolume?: number
  LastUpdated?: string
  NextEarnDate?: string
  LastClosed?: number
  Status?: number

  constructor (price: Price) {
    if (price != null) {
      this.Open = price.Open
      this.Close = price.Close
      this.Last = price.Last
      this.Bid = price.Bid
      this.BidYtm = price.BidYtm
      this.DailyBid = price.DailyBid
      this.DailyBidYtm = price.DailyBidYtm
      this.Volume = price.Volume
      this.AvgVolume = price.AvgVolume
      this.LastUpdated = price.LastUpdated
      this.NextEarnDate = price.NextEarnDate
      this.LastClosed = price.LastClosed
      this.Status = price.Status
    }
  }

  getLast (): number {
    if (this.Status === PreMarketStart || this.Status === PreMarketEnd) { // support pre after price for some us stocks
      return this.LastClosed
    } else {
      return this.Last
    }
  }

  getClose (): number {
    return this.Close
  }

  getChange (): number {
    const last = this.getLast()
    const close = this.getClose()
    if (close && last) {
      return last - close
    }
    return 0
  }

  getChangeRate (): number {
    const change = this.getChange()
    const closed = this.getClose()
    if (change) {
      return (change / closed) * 100
    }
    return 0
  }

  getPreAfterChange (): number {
    if (this.Status === PreMarketStart || this.Status === PreMarketEnd) {
      if (this.Last && this.LastClosed) {
        return (this.Last - this.LastClosed)
      }
    }
    return null
  }

  getPreAfterChangeRate (): number {
    const change = this.getPreAfterChange()
    if (change) {
      return (change / this.LastClosed) * 100
    }
    return null
  }
}

export interface StockPrice {
  Symbol: ContractSymbol;
  Price: Price;
  ExRate?: number;
}

export interface WatchSymbol extends StockPrice {
  WatchListId: number;
  NextEarnDate: string;
  Alert: boolean;
  Rank?: number;
  Target?: number;
  IsDownload?: boolean;
  IsDownloading?: boolean;
  Portfolio?: BasePortfolio;
}

export interface WatchCrypto {
  WatchListId: number;
  Alert: boolean;
  Rank?: number;
  Target?: number;
  Symbol: CryptoSymbol;
}

export interface AlertTypeInfo{
  type: 'Stock' | 'Option' | 'Bond';
  symbolId?: number;
  optionId?: number;
}

export interface PriceAlert {
  PriceAlertId: number;
  AlertPrice: number;
  Type: string;
  TriggerToDo: boolean;
  Created?: string;
  CreatedStr?: string;
  IsDeleting?: boolean;
  SymbolId?: number;
  OptionId?: number;
}

export interface PriceMsgContent {
  Name: string;
  Value: number;
  LastTime?: string;
  Status: number;
}

export interface OptionMsgContent extends PriceMsgContent {
  ConId?: number;
  Otm: number;
  StockPrice: number;
  Target: number;
}

export interface TransactionItem {
  SymbolTransId?: number;
  SymbolId: number;
  Quantity: number;
  CostPerShare: number;
  TransDate: string;
  Commission: number;
  Type: string;
}
