import store from '../store'
import utility from '@/common/utility'

export interface SocketMsg {
  MsgId: number;
  Action: string;
  Content?: any;
}

export class ClientSocket {
  Socket: WebSocket
  DataSource = 'Server'
  TryTimes = 0
  Messages: Array<SocketMsg> = []
  OnMessage: Array<(msg: SocketMsg) => void> = []
  OnOpen: () => void = null
  OnError: (err: Event) => void = null
  isConnecting = false
  timeoutInterval = 0
  autoConnect = true
  context: object = null

  constructor (dataSource: string) {
    this.DataSource = dataSource
  }

  private getWsUrl () {
    if (store.state.user == null) {
      return null
    }
    const jwt = store.state.user.jwt
    const ws = location.protocol === 'https:' ? 'wss' : 'ws'
    let url: string = null
    if (this.DataSource === 'Local') {
      url = `ws://localhost:12479/ws?jwt=${jwt}&uid=${store.state.user.uid}`
    } else {
      url = `${ws}://${window.location.host}/api/ws?jwt=${jwt}&uid=${store.state.user.uid}`
      if (window.location.host.startsWith('localhost') || window.location.host.startsWith('192.168')) {
        url = `${ws}://localhost:5000/api/ws?jwt=${jwt}&uid=${store.state.user.uid}`
      }
    }
    return url
  }

  public init () {
    this.autoConnect = true
    if (typeof (WebSocket) !== 'undefined') {
      if (this.Socket && this.Socket.readyState < 2) {
        return
      }
      const url = this.getWsUrl()
      if (!url) {
        return
      }
      try {
        // console.log('Connecting to: ' + url)
        this.Socket = new WebSocket(url)
        // 监听socket连接
        this.Socket.onopen = () => {
          // the connection maybe connecting,but not connected here.
          this.sendMessages()
          if (this.OnOpen) {
            this.OnOpen()
          }
        }
        // 监听socket错误信息
        this.Socket.onerror = err => {
          // the connection maybe connecting,but not connected here.
          if (this.OnError) {
            this.OnError(err)
          }
          this.reconnect()
        }
        // 监听socket消息
        this.Socket.onmessage = msg => {
          const message = JSON.parse(msg.data) as SocketMsg
          if (message.Action === 'resAcc') this.sendDirectMsg('sendContext', this.context)
          if (this.OnMessage.length > 0) {
            for (let i = 0; i < this.OnMessage.length; i++) {
              this.OnMessage[i](message)
            }
          }
        }
        // on Close
        this.Socket.onclose = () => {
          this.reconnect()
        }
      } catch (e) {
        // console.log(e)
      }
    }
  }

  public sendContext (context: object) {
    this.context = context
    // console.log('sending', this.DataSource, context)
    this.sendDirectMsg('sendContext', context)
  }

  private reconnect () {
    if (!this.autoConnect) return
    if (this.isConnecting) {
      return
    }
    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval)
    }
    this.isConnecting = true
    this.timeoutInterval = setTimeout(() => {
      this.init()
      this.isConnecting = false
    }, 4000)
  }

  stopAutoConnect () {
    this.autoConnect = false
  }

  public sendDirectMsg (action: string, content: any) {
    if (this.Socket != null && this.Socket.readyState === 1) {
      try {
        // console.log('sendDirectMsg', this.DataSource, content)
        this.Socket.send(JSON.stringify({ MsgId: 0, Action: action, Content: content }))
      } catch (e) {
      }
    }
  }

  private sendMessages () {
    if (this.TryTimes > 3) {
      this.TryTimes = 0
      return
    }
    if (this.Socket != null && this.Socket.readyState === 1) {
      this.sendDirectMsg('sendContext', this.context)
      while (this.Messages.length > 0) {
        const msg = this.Messages.shift()
        console.log('sent delay message', this.DataSource, msg)
        try {
          this.Socket.send(JSON.stringify(msg))
        } catch (e) {
          console.log(e)
        }
      }
      this.TryTimes = 0
    } else {
      this.TryTimes++
      setTimeout(() => {
        this.sendMessages()
      }, 200)
    }
  }

  public addMessageCallBack (callback: (msg: SocketMsg) => void) {
    this.OnMessage.push(callback)
  }

  public removeMessageCallBack (callback: (msg: SocketMsg) => void) {
    utility.removeArrayItem(this.OnMessage, callback)
  }

  public sendMsg (msg: SocketMsg) {
    if (this.Socket == null || this.Socket.readyState !== 1) {
      if (msg.Action !== 'sendContext') { this.Messages.push(msg) }
      return
    }
    console.log('send msg', this.DataSource, msg)
    try {
      this.Socket.send(JSON.stringify(msg))
    } catch (e) {
    }
  }

  changeSource (source: string) {
    if (this.DataSource !== source) {
      this.DataSource = source
      this.close()
    }
  }

  close () {
    this.autoConnect = false
    if (this.Socket) {
      this.Socket.close()
      this.Socket = null
    }
  }
}

const server = new ClientSocket('Server')
const local = new ClientSocket('Local')

export default {
  Server: server,
  Local: local,
  GetSocketServer (): ClientSocket {
    const dataSource = localStorage.getItem('StockDataSource')
    if (dataSource === 'Local') return local
    return server
  },
  InitAll (callback: (msg: SocketMsg) => void) {
    server.init()
    server.addMessageCallBack(callback)
    local.init()
    local.addMessageCallBack(callback)
  },
  SendAll (context: Record<string, any>) {
    if (localStorage.getItem('StockDataSource') === 'Local') {
      server.sendContext(null)
      local.sendContext(context)
    } else {
      server.sendContext(context)
      local.sendContext(null)
    }
  },
  RemoveAll (callback: (msg: SocketMsg) => void) {
    server.removeMessageCallBack(callback)
    local.removeMessageCallBack(callback)
  }
}
