













import { ContractSymbol } from '@/services/data'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SymbolName extends Vue {
  name = 'SymbolName'
  @Prop() Symbol: ContractSymbol
  @Prop() SymbolClass: string
}
