import {
  ContractSymbol, PriceAlert, PriceCls, StockPrice, OptionPrice, AlertTypeInfo, OptionSymbol, SymbolType
} from '@/services/data'
import ServiceBase from '@/services/serviceBase'

export interface SymbolPriceAlert {
  Symbol: { Symbol: ContractSymbol; Alerts: PriceAlert[] }[];
  Name: string;
  Option: { Option: OptionSymbol; Alerts: PriceAlert[] }[];
}

export interface RssFeeds {
  SymbolId: number;
  FeedIcon?: string;
  Link: string;
  FeedName: string;
}

export interface RssNews {
  Title: string;
  Author: string;
  Link: string;
  Icon: string;
  PublishDate: string;
}

export interface OptionScanRes {
  Symbol: ContractSymbol;
  Expirations: string[];
  Strikes: number[];
  Multiplier: number;
}

export interface OptionWatchData extends OptionPrice {
  IsWatched: boolean;
}

export default {
  async getSymbolPriceAsync (SymbolId: number) {
    const res = await ServiceBase.tryPostResult<StockPrice>('/symbol/getSymbolWithPrice', { SymbolId })
    if (res.Result) {
      res.Result.Price = new PriceCls(res.Result.Price)
    }
    return res
  },
  async getRssNewsAsync (SymbolId: number) {
    return await ServiceBase.tryPostResult<RssNews[]>('/symbol/getRssNews', { SymbolId })
  },
  async addOrUpdateBatchAlertPrice (models: { AlertInfo: AlertTypeInfo; PriceAlerts: PriceAlert[] }[]) {
    return await ServiceBase.tryPostBooleanResult('/symbol/addOrUpdateBatchAlertPrice', models)
  },
  async addOrUpdateAlertPriceAsync (alertInfo: AlertTypeInfo, model: PriceAlert[]) {
    return await ServiceBase.tryPostBooleanResult('/symbol/addOrUpdateAlertPrice', {
      AlertInfo: alertInfo,
      PriceAlerts: model
    })
  },
  async getAlertPricesAsync (alertInfo: AlertTypeInfo) {
    return await ServiceBase.tryPostResult<PriceAlert[]>('/symbol/getAlertPrices', alertInfo)
  },
  async getAllAlertPricesAsync () {
    return await ServiceBase.tryPostResult<SymbolPriceAlert>('/symbol/getAllAlertPrices')
  },
  async removePriceAlertsAsync (PriceAlertId: number) {
    return await ServiceBase.tryPostBooleanResult('/symbol/removePriceAlerts', { PriceAlertId })
  },
  async updateFromYahooAsync () {
    return await ServiceBase.tryPostBooleanResult('/symbol/updateFromYahoo')
  },
  async searchSymbolsAsync (input: string, type: SymbolType) {
    return await ServiceBase.tryPostResult<ContractSymbol[]>('/symbol/searchSymbols', { input, type })
  },
  async searchOptionsAsync (input: string, expiration: string) {
    return await ServiceBase.tryPostResult<OptionScanRes>('/symbol/searchOptions', { input, expiration })
  },
  async getOptionDefInfo (symbolId: number) {
    return await ServiceBase.tryPostResult<OptionScanRes>('/symbol/getOptionDefInfo', { symbolId })
  },
  async getWatchedOptions (SymbolId: number) {
    return await ServiceBase.tryPostResult<OptionSymbol[]>('/symbol/getWatchedOptions', { SymbolId })
  },
  async generateOptions (SymbolId: number, Expiration: string) {
    return await ServiceBase.tryPostResult<{ Data: OptionWatchData[]; MarketState: number }>('/symbol/generateOptions', { SymbolId, Expiration })
  }
}
